@import "../../variables"

.collection-words
    cursor: pointer
    overflow-y: auto
    position: absolute
    top: 66px
    margin: 0
    z-index: 3

    &-title
        padding: 14px 8px
        color: $main-text
        font-size: 16px
        font-weight: 700

    &-chip
        background: #fff
        color: 'mediumblue'
        padding: 4px 8px
        font-size: 20px
        font-weight: 300
        font-size: 14px
        margin: -12px 6px 0 0 !important
        font-family: 'Supria Sans Regular', sans-serif !important

    .box-close
        padding: 10px
        border-radius: 0
        color: $main-text

    &-count
        color: midnightblue
        padding: 0 0 0 8px
        font-weight: 600

    &-content
        padding: 6px
        border-radius: 0
        width: 270px
        max-height: calc(100vh - 170px)
        display: block
        scrollbar-width: none
        overflow-y: auto
        overflow-x: hidden
        position: relative
        z-index: 1

        .MuiChip-root
            margin: 2px
