:root
    --outer-color: #fff
    --width: 200px
    --radius: calc((var(--width)/20))
    --bg-size: var(--radius)
    --lem: 10%
    --inner-color: $main-text
    --inner-text-color: #fff
    --minimizer: 7

.img-logo
    margin-left: 10px
    height: 66px
    filter: grayscale(0.5) opacity(0.8)

    .pattern
        background-color: var(--inner-color)
        color: var(--inner-text-color)

    .container
        display: grid
        margin: auto
        width: 60%

    .item

    .wrap
        margin: calc(2*var(--radius))

    .postage
        position: relative
        width: var(--width)
        height: var(--width)
        background: var(--outer-color)

    .inner
        position: absolute
        top: var(--lem)
        left: var(--lem)
        width: calc(100% - 2*var(--lem))
        height: calc(100% - 2*var(--lem))

    .price
        position: absolute
        bottom: var(--radius)
        right: var(--radius)
        font-size: calc(var(--width)/var(--minimizer))
        font-weight: bold
        text-align: right
        bottom: 2%
        right: 5%

    .postage:before
        position: absolute
        top: calc(-1*var(--width)/20)
        content: ""
        background: radial-gradient(circle, transparent var(--radius), var(--outer-color) var(--radius))
        background-size: calc(var(--bg-size)*4) calc(var(--bg-size)*2)
        background-position: 0px calc(-1*var(--bg-size))
        width: 100%
        height: calc(var(--width)/10)
        z-index: -1

    .postage:after
        position: absolute
        bottom: calc(-1*var(--width)/20)
        content: ""
        display: block
        background: radial-gradient(circle, transparent var(--radius), var(--outer-color) var(--radius))
        background-size: calc(var(--bg-size)*4) calc(var(--bg-size)*2)
        background-position: 0 calc(-1*var(--bg-size))
        width: 100%
        height: calc(var(--width)/10)
        z-index: -1

    .postage-90
        -webkit-transform: rotate(90deg)
        -moz-transform: rotate(90deg)
        -o-transform: rotate(90deg)
        -ms-transform: rotate(90deg)
        transform: rotate(90deg)
        position: absolute
        margin-top: 0

    .corner
        position: absolute
        width: var(--radius)
        height: var(--radius)
        background: var(--outer-color)
        z-index: 1

    .corner-1
        top: -5%
        left: -5%

    .corner-2
        top: -5%
        right: -5%

    .corner-3
        bottom: -5%
        left: -5%

    .corner-4
        bottom: -5%
        right: -5%
