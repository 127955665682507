@import "../../variables"

.stamp-detail
    width: 100%
    height: calc(100% - 103px)

    &-container
        height: 100%
        background-color: #fff

    &-image-half
        display: flex
        align-items: top
        justify-content: right
        // padding: 24px 24px 24px 0 !important

    &-info
        padding-left: 24px
        border-left: 1px solid

        &-head
            padding-bottom: 24px
            padding-top: 10px

        button
            height: 44px
            color: $main-text
            background-color: $bg
            border-color: #ccc
            min-width: 50px
            width: 50px
            outline: none
            cursor: pointer
            margin-left: 12px

            &:hover
                opacity: 0.7
                border-color: #bbb

    &-box
        text-align: right

    &-name.MuiTypography-root
        color: #556
        letter-spacing: 1px
        font-size: 30px
        line-height: 34px
        margin-top: 7px
        font-family: 'Supria Sans Regular', sans-serif

    &-heading2.MuiTypography-root
        font-weight: 400
        font-size: 30px
        margin: 32px 0 16px
        color: #556
        font-family: 'Supria Sans Regular', sans-serif

    &-collection
        img
            margin: 0 8px 8px 0 !important
