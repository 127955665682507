@import "../../variables"

.attribute
    font-size: 14px
    padding: 1em 0

    &-label
        text-transform: uppercase
        width: 200px
        color: #969696
        letter-spacing: 1px

    &-medium
        font-size: 1.1em
        font-weight: 600

    &-large
        font-size: 15px
        line-height: 1.6em

    &-value
        color: #442

.stamp-detail-info
    .attribute
        border-bottom: 1px dotted #e5e5e5
        padding: 1em

.collection-after
    color: #333
    margin: 2em 0 0
    text-align: justify

    > div
        padding-bottom: 0
