.stamp-sneak
    & > .MuiPaper-root
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1)
        border-radius: 2px

    &:hover .stamp-sneak-name
        background-color: #f5f5f5 !important

    &-img
        &-ca
            background-color: #e5e5e5

        &-skelet
            background-color: #ededed
            width: 100%
            height: 100%

    &-name
        font-size: 16px
        padding: 8px
        color: #666
        font-size: 14px

        &-skeleton
            margin: 8px
            position: relative
