.theme-heading
    display: flex
    flex-direction: columns
    justify-content: center
    align-items: center
    padding-bottom: 12px

    .pagination
        margin: 4px 0 12px
        box-shadow: none
        border-radius: 0

        button
            border-right: 0
            color: #333 !important
            background-color: #fff !important
            border-color: #ccc !important
            font-family: 'Supria Sans Regular', sans-serif !important
            line-height: 14px
            font-size: 14px
            padding-top: 9px
            padding-bottom: 8px
            min-width: 40px

            &.Mui-disabled
                border-right: 0

            &:active
                box-shadow: none

            &.active-index.Mui-disabled
                background-color: #333 !important
                color: #fff !important
                font-weight: 700 !important
                border: 0

            &.active-index
                background-color: #ddd !important
                color: #333 !important
                font-weight: 700 !important

            &.label,
            &.label:hover
                font-weight: 700 !important
                background-color: #999 !important
                border-radius: 0
                font-weight: 700 !important
                line-height: 24px
                letter-spacing: 2px
                color: #000 !important
                background-color: #f5f5f5 !important

            &:hover
                background-color: #eaeaea !important
                border-radius: 0

    h1
        color: #222 !important
        font-size: 24px !important
