// Variables
$color-accent: #177E89
$color-light: #ffffff
$color-dark: #2a2a2a
$menu-link-padding: 10px 25px
$breakpoint: 1050px
$mega-menu-multiLevel-colWidth: calc(100% / 3 + 0%)
$mobile-menu-back-height: calc(1.4em + 40px)
$mobile-menu-back-offset: calc(0px - (1.4em + 40px))
$menu-mobile-width: 160px

.head-page-mobile
    display: none

body
    padding: 0
    margin: 0

*, *:before, *:after
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box

nav
    position: relative
    ul, li
        list-style: none
        padding: 0
        margin: 0
    & > ul > li
        position: relative
    & > ul > li > ul
        position: absolute
    a
        color: #000
        display: block
        text-decoration: none
        &:hover, &:visited
            text-decoration: none
    & ul ul li
        background: rgba(255, 255, 255, 0.95)
    & ul li ul
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15)
    & ul li a
        padding: 10px
        text-decoration: none
        color: #333
        &:hover
            background: rgba(200, 200, 200, 0.6)
    & ul li ul
        overflow-y: auto
        max-height: 70vh
        z-index: 5
    & ul ul li
        width: max-content
        min-width: 100%
        max-width: 400px
        border-bottom: 1px dotted #ddd
    .random-item
        cursor: pointer

.sneak-menu
    .mega-menu
        width: 100%
        border: 1px solid #ddd
        border-radius: 2px

    li
        width: 100% !important
        border-bottom: 1px dotted #ddd
        &:last-of-type
            border-bottom: none

        a
            background: #fff
            cursor: pointer

        a:hover
            background: #ddd !important

.menu-bar
    background: $color-light
    display: flex
    justify-content: center
    margin-bottom: 24px

.menu-link
    background: $color-light
    transition: background .2s, color .2s
    position: relative
    z-index: 1

.mega-menu-header
    font-size: 1.2em
    text-transform: uppercase
    font-weight: bold

.mega-menu
    z-index: 10

.mega-menu--multiLevel
    flex-direction: column

.menu-length-info
    display: none

@media all and (min-width: $breakpoint + 1px)

    .menu > li:hover > a
        background: rgba(200, 200, 200, 0.3)
    .nav
        background: $color-light
        > nav
            max-width: 900px
            margin: 0 auto

    .menu [aria-haspopup="true"] ~ ul
        display: none

    .menu-bar
        position: relative
        > li
            > [aria-haspopup="true"]
                &:hover
                    ~ ul
                        display: flex
                        transform-origin: top
                ~ ul
                    &:hover
                        display: flex

        // FUNCTIONALITY: Keep mega menu open
        &:focus-within
            > [aria-haspopup="true"]
                ~ ul
                    display: flex

    .mega-menu
        // LAYOUT: Mega menu
        // position: absolute
        top: 100%
        left: 0
        width: max-content
        // FUNCTIONALITY: Keep mega menu open
        &:hover
            display: flex
        a
            &:hover
                background: tint($color-accent, 85%)

    .mega-menu--multiLevel
        // LAYOUT: Multi level columns
        > li
            width: $mega-menu-multiLevel-colWidth
            > [aria-haspopup="true"]
                ~ ul
                    left: $mega-menu-multiLevel-colWidth
                    width: $mega-menu-multiLevel-colWidth
                    ul
                        width: 100%
                        left: 100%

        li
            &:hover
                > [aria-haspopup="true"]
                    ~ ul
                        display: block
                        transform-origin: left
                        animation: flyout .2s ease-out

            &:focus-within
                > [aria-haspopup="true"]
                    ~ ul
                        display: block

    // STYLING: Flat mega menu columns
    .mega-menu--flat
        > *
            flex: 1

    // Hide mobile specific elements
    .mobile-menu-trigger,
    .mobile-menu-header,
    .mobile-menu-back-item
        display: none

@media all and (max-width: $breakpoint)
    .stamp-detail
        margin-top: 40px
    .head-page-mobile
        display: block
        position: relative
        height: 48px
        width: 100%
    .head-page-desktop
        display: none

    .nav
        padding: 20px

    .mobile-menu-trigger,
    .mobile-menu-header,
    .mobile-menu-back-item
        display: block

    .menu-length-info
        margin-left: 4px
        display: block
    #myElement
        cursor: pointer
    .mobile-menu-trigger
        position: fixed
        top: 0
        left: 0
        padding: 8px 10px
        background-color: #f3f3f3
        z-index: 1102
        height: 55px
        cursor: pointer
        div
            width: 31px
            height: 4px
            background-color: #666
            margin: 7px 0
            border-radius: 2px

    .mobile-menu-header
        order: -1
        a
            padding: $menu-link-padding
            visibility: visible
            background: #ccc
            text-align: right
            display: flex
            justify-content: flex-end

    .menu-bar
        flex-direction: column
        position: fixed
        top: 0
        left: -100%
        height: 100vh
        width: $menu-mobile-width
        max-width: $menu-mobile-width
        max-width: 90%
        overflow-x: hidden
        transition: left .3s
        box-shadow: 1px 0px 2px 0px rgba(0,0,0,0.25)
        justify-content: start
        z-index: 1102
        > li
            > [aria-haspopup="true"]
                ~ ul
                    display: flex
                    flex-direction: column
                    background: $color-light
                    position: absolute
                    left: 100%
                    top: 0
                    max-height: 100vh
                    width: 100%
                    transition: left .3s
                    > li
                        > [aria-haspopup="true"]
                            font-size: 1.2em
                            ~ ul
                                a
                                    padding-left: 40px
                                > li
                                    > [aria-haspopup="true"] ~ ul a
                                        padding-left: 80px
                    [aria-haspopup="true"] 
                        ~ ul
                            max-height: 0px
                            transform-origin: top
                            transform: scaleY(0)
                            transition: max-height .1s

    .mega-menu-content
        padding: $menu-link-padding

    .mobile-menu-back-item
        order: -1
        a
            background: tint(grey, 70%)
            color: $color-dark
            max-height: $mobile-menu-back-height
            margin-top: $mobile-menu-back-offset
            pointer-events: none

    #toggle:checked
        ~ ul
            left: 0

    .menu-bar
        > li
            > [aria-haspopup="true"]
                &:focus
                    ~ ul
                        left: 0
                ~ ul
                    margin-top: $mobile-menu-back-height
                    &:hover, &:focus-within
                        left: 0
                    [aria-haspopup="true"] 
                        &:focus
                            ~ ul
                                max-height: 500px
                                animation: dropdown .3s forwards
                    li
                        &:focus-within
                            > [aria-haspopup="true"]
                                ~ ul
                                    max-height: 500px
                                    transform: scaleY(1)
            &:focus-within ~ .mobile-menu-header a
                visibility: hidden
    // TODO: remove if want multilevel dropdowns
    .mega-menu.mega-menu--multiLevel
        display: none !important

    .stamp-detail-image-half
        display: flex
        justify-content: center

    .stamp-detail-info
        padding: 24px 0

@media (hover: none)
    .mobile-menu-trigger
        &:hover
            ~ ul
                left: 0

    .menu-bar
        > li
            > [aria-haspopup="true"]
                &:hover
                    ~ ul
                        left: 0
                ~ ul
                    &:hover
                        left: 0
                    [aria-haspopup="true"]
                        &:hover
                            ~ ul
                                max-height: 500px
                                animation: dropdown .3s forwards
                        ~ ul

                            &:hover
                                max-height: 500px
                                transform: scaleY(1)
            &:hover ~ .mobile-menu-header a
                visibility: hidden

@keyframes dropdown
    0%
        opacity: 0
        transform: scaleY(0)
    50%
        opacity: 1
    100%
        transform: scaleY(1)

@keyframes flyout
    0%
        opacity: 0
        transform: scaleX(0)
    100%
        opacity: 1
        transform: scaleX(1)

.menu-close-button
    width: 20px
    height: 20px
    position: relative
    cursor: pointer

.menu-close-button:before,
.menu-close-button:after
    content: ''
    position: absolute
    width: 2px
    height: 100%
    background-color: #333

.menu-close-button:before
    transform: rotate(45deg)

.menu-close-button:after
    transform: rotate(-45deg)

/* Optional: Hover effect */
.menu-close-button:hover:before,
.menu-close-button:hover:after
    background-color: #555

@media all and (max-width: $breakpoint)
    .third-row-wrapper
        margin-top: 40px
    .number-index
        display: none !important
