.login-dialog
    padding: 16px

.google
    background-color: #fff !important
    background-image: url('https://cdn.cdnlogo.com/logos/g/35/google-icon.svg') !important
    border: 1px solid #dfdfe8 !important
    color: #242428 !important
    background-position: 16px !important
    background-repeat: no-repeat !important
    background-size: 24px !important
    border: 1px solid !important
    cursor: pointer !important
    display: block !important
    font-size: 15px !important
    margin-top: 16px !important
    padding: 16px 50px !important
    position: relative !important
    text-align: center !important
    -webkit-transition: background-color .1s ease,border-color .1s ease
    transition: background-color .1s ease,border-color .1s ease
    width: 100% !important
