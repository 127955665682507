.stamp-preview
    cursor: pointer
    overflow-y: auto
    height: calc(100vh - 40px)
    scrollbar-width: none

    &-card
        &.MuiCard-root
            max-width: 345px,
            background-color: #000
            color: #fff
            padding: 6px
            // border-radius: 6px 6px 0 6px,
            border-radius: 0
            box-shadow: 0 0 26px 1px rgba(181,181,181,1)

        &-header
            .MuiCardHeader-title
                line-height: 1.1
                max-height: 79px
                overflow-y: auto
                padding-bottom: 3px

                &::-webkit-scrollbar
                    width: 0
                    background: transparent

            .MuiCardHeader-subheader
                color: #aaa
                margin-top: 4px

    &-content
        font-size: 14px

    &-scroll-content
        max-height: calc(100vh - 265px)
        overflow-y: auto

        &::-webkit-scrollbar
            width: 0
            background: transparent
