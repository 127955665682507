@use "./components/ReloadButton/reload-button.sass"
@use "./components/Story/story.sass"
@use "./components/Attribute/attribute.sass"
@use "./components/StampPreview/stamp-preview.sass"
@use "./components/StampDetail/stamp-detail.sass"
@use "./components/StampSidePanel/stamp-side-panel.sass"
@use "./components/StampSneak/stamp-sneak.sass"
@use "./components/CollectionWords/collection-words.sass"
@use "./components/BoxClose/box-close.sass"
@use "./components/ThemeHeading/theme-heading.sass"
@use "./components/LoginDialog/login-dialog.sass"
@use "./components/ImgLogo/img-logo.sass"
@use "./components/ThemesMenu/themes-menu.sass"
@import "./variables"

@font-face
    font-family: 'Supria Sans Regular'
    src: url('fonts/Supria Sans Regular.otf') format('opentype')

iframe
    display: none

.MuiIconButton-root
    opacity: 0.2

.MuiIconButton-root:hover
    opacity: 0.5

.top-divider
    padding-top: 30px
    padding-bottom: 56px

.heading-button
    text-transform: none !important
    font-family: monospace !important

.main-heading.MuiTypography-h2
    font-family: 'Supria Sans Regular'
    font-size: 22px
    color: #000
    padding: 20px 0
    text-align: center

.main-heading-lower.MuiTypography-h2
    font-family: 'Supria Sans Regular'
    font-size: 22px
    color: #000
    padding: 20px 0 10px
    text-align: center

.theme-intro
    padding: 0 20px 20px
    color: #000
    font-size: 14px
    line-height: 24px
    margin: 0

body
    color: #999
    font-size: 14px
    font-family: 'Supria Sans Regular'

header
    padding-right: 0 !important
    width: 1200px

    h1
        font-weight: 700
        font-size: 24px
        color: #000 !important

.visually-hidden
    position: absolute
    left: -100vw

.loading
    background-color: #fff
    height: 100vw
    z-index: 0
    opacity: 1

    img
        display: none
        max-height: 100%
        max-width: 100%
        width: auto
        height: auto
        position: fixed
        top: 0
        bottom: 0
        left: 0
        right: 0
        margin: auto
        z-index: 5

.footer
    color: #000
    text-align: justify

    & > div > div > div
        border-top: 1px solid $border

    button.social
        height: 64px
        width: 64px
        background-color: transparent
        color: $main-text

    button.law
        background-color: transparent
        color: $main-text
        text-transform: none
        text-decoration: underline
        min-width: unset
        padding: 0

.reload-button-container
    margin: 20px auto 0
    text-align: center

footer
    margin-top: 32px

    & > div
        border-bottom: 1px solid $border

    &-global
        padding: 46px 0 !important

    &-initials
        color: #000 !important

.reload-button-box
    position: fixed
    bottom: 20px
    left: 20px
    z-index: 3

    & button
        background-color: #fff !important

.third-row-wrapper
    position: relative
    background-color: #fff

.navbar
    position: relative !important

    .first-row-reload button
        height: 66px
        width: 66px
        background-color: transparent
        color: $main-text

    .first-row
        display: flex
        justify-content: center

    .second-row
        // background-color: $nav-bg
        // border-top: 1px solid $border
        // border-bottom: 1px solid $border
        color: $nav-text

        button
            background-color: transparent
            color: $main-text

    .chip
        background: blue
        color: white
        padding: 8px 16px
        font-size: 20px
        border-radius: 4px
        font-weight: 300
        font-size: 14px
        margin: 0 16px

.MuiAppBar-root
    box-shadow: none !important
    background-color: $bg !important

.document
    // position: absolute
    // top: 67px
    padding: 20px 100px
    color: #000
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif
    font-size: 16px
    line-height: 28px
    margin: 0

// Pinterest style grid
// https://codepen.io/traceypooh/pen/pWjOdq

.logo
    padding: 32px 20px 18px 20px

    a
        font-weight: 700
        letter-spacing: 2px
        font-size: 28px
        color: $main-text
        text-decoration: none

.head-page-mobile
    font-weight: 700
    letter-spacing: 2px
    font-size: 28px
    text-align: center
    background-color: #f3f3f3
    line-height: 55px
    height: 55px
    position: fixed
    z-index: 1101
    top: 0
    a
        color: $main-text !important
        text-decoration: none

.playground-module
    width: 100%
    min-height: 300px

.results
    display: grid
    /* fit as many columns as possible, 180px wide each: *
    // grid-template-columns: repeat(auto-fill, 200px)
    /* each row is 20px high -- we always span 2+ *
    // margin: 0 auto
    // grid-gap: 20x
    // grid-auto-rows: minmax(20px, auto)
    justify-content: center
    min-height: 800px
    padding-top: 24px
    margin-left: 16px

.res-ref
    width: 100%
    height: 15px
    position: absolute
    top: 0

.unselectable
    user-select: none
    padding: 0 10px

// .menu
//     display: flex
//     justify-content: center
//     font-family: monospace, 'Supria Sans Regular', sans-serif
//     font-size: 13px

//     & > div > div
//         border-bottom: 1px solid $border

//     & ul
//         list-style: none
//         display: flex
//         padding: 0

//     & ul ul
//         flex-wrap: wrap

//     & ul ul li
//         background: rgba(255, 255, 255, 0.95)

//     & ul li ul
//         overflow-y: auto
//         max-height: 70vh
//         z-index: 5

//     & ul li
//         position: relative

//     & ul li a
//         display: block
//         padding: 10px
//         text-decoration: none
//         color: #333

//     & ul li ul
//         position: absolute
//         display: none
//         box-shadow: 0 0 5px rgba(0, 0, 0, 0.15)

//     & ul ul li
//         width: max-content
//         min-width: 100%
//         max-width: 400px

//     & ul a
//         color: $nav-text
//         cursor: pointer

//     & > div > ul > li:hover > a
//         background: rgba(200, 200, 200, 0.3)

//     & ul a:hover
//         background: rgba(200, 200, 200, 0.6)

//     & ul li:hover > ul
//         display: flex

//     & ul ul ul
//         top: 0
//         left: 100%
